import React from "react"
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const RespMsg = ({ hidden, content, type }) => {
  return (
    <div
      className={type == "error" ? "borderMeError" : "borderMeCorrect"}
      hidden={hidden}
    >
      <p style={{ color: type == "error" ? "red" : "green" }}>
        <FontAwesomeIcon
          icon={type == "error" ? faTimesCircle : faCheckCircle}
        />{" "}
        &nbsp; {content}
      </p>
    </div>
  )
}

export default RespMsg
