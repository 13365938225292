import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Dropdown } from "react-bootstrap"
import ButtonLink from "../../components/buttonLink"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import RespMsg from "../../components/respMsg"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const LogGrowthSection4 = ({ location }) => {
  const { t } = useTranslation()

  const pageContent = {
    breadcrumb: parse(t("Topic2_Section4_breadcrumb[1]")),
    title: parse(t("Topic2_Section4_title")),
    instruction: parse(t("Topic2_Section4_instructions")),
    choiceTitles: [
      parse(t("Topic2_Section4_choiceTitles[0]")),
      parse(t("Topic2_Section4_choiceTitles[1]")),
      parse(t("Topic2_Section4_choiceTitles[2]")),
    ],
    labels: [
      parse(t("Topic2_Section4_labels[0]")),
      parse(t("Topic2_Section4_labels[1]")),
      parse(t("Topic2_Section4_labels[2]")),
      parse(t("Topic2_Section4_labels[3]")),
      parse(t("Topic2_Section4_labels[4]")),
      parse(t("Topic2_Section4_labels[5]")),
    ],
    msgBox: [
      parse(t("Topic2_Section4_msgBox[0]")),
      parse(t("Topic2_Section4_msgBox[1]")),
    ],
  }
  //===============================================================================
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [choice, setChoice] = useState({
    cc: pageContent.choiceTitles[0],
    constant: pageContent.choiceTitles[0],
    dd: pageContent.choiceTitles[0],
    changes: pageContent.choiceTitles[0],
    imm: pageContent.choiceTitles[0],
    unl: pageContent.choiceTitles[0],
  })

  const onSubmit = () => {
    if (
      choice.cc == pageContent.choiceTitles[0] &&
      choice.constant == pageContent.choiceTitles[1] &&
      choice.dd == pageContent.choiceTitles[0] &&
      choice.changes == pageContent.choiceTitles[0] &&
      choice.imm == pageContent.choiceTitles[2] &&
      choice.unl == pageContent.choiceTitles[1]
    ) {
      setMsgType("correct")
      setMsgContent(pageContent.msgBox[1])
      setNextBtnEnabled(true)
    } else {
      setMsgType("error")
      setMsgContent(pageContent.msgBox[0])
      setNextBtnEnabled(false)
    }
    setShowMsg(true)
  }

  //===============================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic2_Section4_pageTitle"))} />

      <MyBreadcrumb
        topicLink="logisticgrowth"
        topicTitle={parse(t("Topic2_Section4_breadcrumb[0]"))}
        currentTitle={pageContent.breadcrumb}
      />
      <div className="homeContent">
        <Row>
          <Col sm="12">
            <TopHeader title={pageContent.title} />
          </Col>
        </Row>

        <div>
          <Instructions content={pageContent.instruction} />
          <Row>
            <Col sm="6">
              {/* carrying capacity========================================================= */}
              <Row>
                <Col sm="6">
                  <strong>{pageContent.labels[0]}</strong>
                </Col>
                <Col sm="6">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="muted"
                      id="dropdown-basic"
                      className="selectBox"
                    >
                      {/* default choice */}
                      {choice.cc}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            cc: pageContent.choiceTitles[0],
                          })
                        }}
                      >
                        {/* first option */}
                        {pageContent.choiceTitles[0]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            cc: pageContent.choiceTitles[1],
                          })
                        }}
                      >
                        {/* second option */}
                        {pageContent.choiceTitles[1]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            cc: pageContent.choiceTitles[2],
                          })
                        }}
                      >
                        {/* third option */}
                        {pageContent.choiceTitles[2]}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              {" "}
              {/* constant========================================================= */}
              <Row>
                <Col sm="6">
                  <strong>{pageContent.labels[1]}</strong>
                </Col>
                <Col sm="6">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="muted"
                      id="dropdown-basic"
                      className="selectBox"
                    >
                      {/* default choice */}
                      {choice.constant}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            constant: pageContent.choiceTitles[0],
                          })
                        }}
                      >
                        {/* first option */}
                        {pageContent.choiceTitles[0]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            constant: pageContent.choiceTitles[1],
                          })
                        }}
                      >
                        {/* second option */}
                        {pageContent.choiceTitles[1]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            constant: pageContent.choiceTitles[2],
                          })
                        }}
                      >
                        {/* third option */}
                        {pageContent.choiceTitles[2]}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="6">
              {" "}
              {/* dd========================================================= */}
              <Row>
                <Col sm="6">
                  <strong>{pageContent.labels[2]}</strong>
                </Col>
                <Col sm="6">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="muted"
                      id="dropdown-basic"
                      className="selectBox"
                    >
                      {/* default choice */}
                      {choice.dd}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            dd: pageContent.choiceTitles[0],
                          })
                        }}
                      >
                        {/* first option */}
                        {pageContent.choiceTitles[0]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            dd: pageContent.choiceTitles[1],
                          })
                        }}
                      >
                        {/* second option */}
                        {pageContent.choiceTitles[1]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            dd: pageContent.choiceTitles[2],
                          })
                        }}
                      >
                        {/* third option */}
                        {pageContent.choiceTitles[2]}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              {" "}
              {/* changes========================================================= */}
              <Row>
                <Col sm="6">
                  <strong>{pageContent.labels[3]}</strong>
                </Col>
                <Col sm="6">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="muted"
                      id="dropdown-basic"
                      className="selectBox"
                    >
                      {/* default choice */}
                      {choice.changes}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            changes: pageContent.choiceTitles[0],
                          })
                        }}
                      >
                        {/* first option */}
                        {pageContent.choiceTitles[0]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            changes: pageContent.choiceTitles[1],
                          })
                        }}
                      >
                        {/* second option */}
                        {pageContent.choiceTitles[1]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            changes: pageContent.choiceTitles[2],
                          })
                        }}
                      >
                        {/* third option */}
                        {pageContent.choiceTitles[2]}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="6">
              {" "}
              {/* imm========================================================= */}
              <Row>
                <Col sm="6">
                  <strong>{pageContent.labels[4]}</strong>
                </Col>
                <Col sm="6">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="muted"
                      id="dropdown-basic"
                      className="selectBox"
                    >
                      {/* default choice */}
                      {choice.imm}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            imm: pageContent.choiceTitles[0],
                          })
                        }}
                      >
                        {/* first option */}
                        {pageContent.choiceTitles[0]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            imm: pageContent.choiceTitles[1],
                          })
                        }}
                      >
                        {/* second option */}
                        {pageContent.choiceTitles[1]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            imm: pageContent.choiceTitles[2],
                          })
                        }}
                      >
                        {/* third option */}
                        {pageContent.choiceTitles[2]}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              {" "}
              {/* unl========================================================= */}
              <Row>
                <Col sm="6">
                  <strong>{pageContent.labels[5]}</strong>
                </Col>
                <Col sm="6">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="muted"
                      id="dropdown-basic"
                      className="selectBox"
                    >
                      {/* default choice */}
                      {choice.unl}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            unl: pageContent.choiceTitles[0],
                          })
                        }}
                      >
                        {/* first option */}
                        {pageContent.choiceTitles[0]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            unl: pageContent.choiceTitles[1],
                          })
                        }}
                      >
                        {/* second option */}
                        {pageContent.choiceTitles[1]}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onSelect={(key, data) => {
                          setChoice({
                            ...choice,
                            // set answer
                            unl: pageContent.choiceTitles[2],
                          })
                        }}
                      >
                        {/* third option */}
                        {pageContent.choiceTitles[2]}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />

          <p>
            <ButtonLink
              onClick={onSubmit}
              btnText={parse(t("Submit"))}
              // btnColor="grey"
              className="btnNext"
              disabled={nextBtnEnabled}
            />
          </p>
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>

        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={parse(t("Previous"))}
              btnLinkPrev="/logisticgrowth/section3"
              disabled={nextBtnEnabled == false}
              nextName={parse(t("Next Topic"))}
              btnLinkNext="/epopulationgrowth"
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default LogGrowthSection4
